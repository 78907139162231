import { isAuth, userData } from "../stores/user.js";
import { get } from 'svelte/store';
import { goto } from "$app/navigation";
import { browser } from "$app/environment";



export async function authorize() {
    //Try to refresh
    let refresh = await fetch(`${import.meta.env.VITE_API_URL}/users/refresh`, {
        method: "POST",
        credentials: "include",
    })
    if (refresh.ok) {
        isAuth.set(true);
        userData.set(await refresh.json());
        return true;
    } 
    else {
        isAuth.set("loading");
        userData.set({
            access_level: 0
        });
    }

    return false;
}

export async function checkAuth(requiredAccessLevel = 0) {
    if (!browser) return true;
    let auth = get(isAuth);
    if (auth === "loading") {
        auth = await authorize();
    }

    if (!auth) return false;

    if (get(userData).access_level < requiredAccessLevel) {
        goto('/');
        return false;
    }

    return true;
}
